import React from 'react'
import styled from 'styled-components'
import {space} from 'style/fns'
import {Section, Container, Span, Text, Anchor} from 'components'

const List = styled.ul`
  ${space}

  > li {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`

function Policy(props) {
  const {title, children} = props
  return (
    <Container mb="sp4" lineHeight="1.5">
      <Text fontSize="fsize-3" mb="sp2">{title}</Text>
      <Container
        color="dark"
        lineHeight="1.5"
        textAlign="df:[left] md:[justify]"
      >
        {children}
      </Container>
    </Container>
  )
}

function PolicySection() {
  return (
    <Section py="sp3" mx="df:[sp3] md:[sp5] lg:[sp6]">
      <Text lineHeight="1.5" textAlign="justify" color="dark" mb="sp4">
        This privacy policy has been compiled to better serve those who are concerned with how{' '}
        their 'Personally Identifiable Information' (PII) is being used online. PII, as{' '}
        described in US privacy law and information security, is information that can be{' '}
        used on its own or with other information to identify, contact, or locate a single{' '}
        person, or to identify an individual in context. Please read our privacy policy{' '}
        carefully to get a clear understanding of how we collect, use, protect or otherwise{' '}
        handle your Personally Identifiable Information in accordance with our website.
      </Text>

      <Policy title="What personal information do we collect from the people that visit our blog, website or app?">
        When ordering or registering on our site, as appropriate, you may be asked to enter your{' '}
        name, email address, mailing address, phone number, social security number or other{' '}
        details to help you with your experience.
      </Policy>

      <Policy title="When do we collect information?">
        We collect information from you when you register on our site, place an order, fill out{' '}
        a form, Use Live Chat, Open a Support Ticket or enter information on our site.

        <Span display="block" mt="sp2">
          Provide us with feedback on our products or services
        </Span>
      </Policy>

      <Policy title="How do we use your information?">
        We may use the information we collect from you when you register, make a purchase,{' '}
        sign up for our newsletter, respond to a survey or marketing communication, surf{' '}
        the website, or use certain other site features in the following ways:

        <List my="sp3" pl="sp4">
          <li>
            To personalize your experience and to allow us to deliver the type of content and{' '}
            product offerings in which you are most interested.
          </li>

          <li>To improve our website in order to better serve you.</li>

          <li>To allow us to better service you in responding to your customer service requests.</li>

          <li>To administer a contest, promotion, survey or other site feature.</li>

          <li>To quickly process your transactions.</li>

          <li>To send periodic emails regarding your order or other products and services.</li>

          <li>To follow up with them after correspondence (live chat, email or phone inquiries)</li>
        </List>
      </Policy>

      <Policy title="How do we protect your information?">
        We do not use vulnerability scanning and/or scanning to PCI standards.

        <Span display="block" mt="sp2">
          An external PCI compliant payment gateway handles all CC transactions.
        </Span>

        <Span display="block" mt="sp2">
          We do not use Malware Scanning.
        </Span>

        <Span display="block" mt="sp2">
          Your personal information is contained behind secured networks and is only accessible{' '}
          by a limited number of persons who have special access rights to such systems, and{' '}
          are required to keep the information confidential. In addition, all sensitive/credit{' '}
          information you supply is encrypted via Secure Socket Layer (SSL) technology.
        </Span>

        <Span display="block" mt="sp2">
          We implement a variety of security measures when a user places an order enters,{' '}
          submits, or accesses their information to maintain the safety of your personal information.
        </Span>

        <Span display="block" mt="sp2">
          All transactions are processed through a gateway provider and are not stored or{' '}
          processed on our servers.
        </Span>
      </Policy>

      <Policy title="Do we use 'cookies'?">
        We do not use cookies for tracking purposes

        <Span display="block" mt="sp2">
          You can choose to have your computer warn you each time a cookie is being sent, or you{' '}
          can choose to turn off all cookies. You do this through your browser settings. Since{' '}
          browser is a little different, look at your browser's Help Menu to learn the correct{' '}
          way to modify your cookies.
        </Span>

        <Span display="block" mt="sp2">
          If you turn cookies off, Some of the features that make your site experience more{' '}
          efficient may not function properly.that make your site experience more efficient{' '}
          and may not function properly.
        </Span>
      </Policy>

      <Policy title="Third-party disclosure">
        We do not sell, trade, or otherwise transfer to outside parties your Personally{' '}
        Identifiable Information unless we provide users with advance notice. This does not{' '}
        include website hosting partners and other parties who assist us in operating our{' '}
        website, conducting our business, or serving our users, so long as those parties agree{' '}
        to keep this information confidential. We may also release information when it's release{' '}
        is appropriate to comply with the law, enforce our site policies, or protect ours or{' '}
        others' rights, property or safety.

        <Span display="block" mt="sp2">
          However, non-personally identifiable visitor information may be provided to other{' '}
          parties for marketing, advertising, or other uses.
        </Span>
      </Policy>

      <Policy title="Third-party links">
        Occasionally, at our discretion, we may include or offer third-party products or services{' '}
        on our website. These third-party sites have separate and independent privacy policies.{' '}
        We therefore have no responsibility or liability for the content and activities of these{' '}
        linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any{' '}
        feedback about these sites.
      </Policy>

      <Policy title="Google">
        Google's advertising requirements can be summed up by{' '}
        <Anchor
          color="primary"
          href="https://support.google.com/adwordspolicy/answer/1316548?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google's Advertising Principles.{' '}
        </Anchor>
        They are put in place to provide a positive experience for users.

        <Span display="block" mt="sp2">
          We use Google AdSense Advertising on our website.
        </Span>

        <Span display="block" mt="sp2">
          Google, as a third-party vendor, uses cookies to serve ads on our site. Google's{' '}
          use of the DART cookie enables it to serve ads to our users based on previous{' '}
          visits to our site and other sites on the Internet. Users may opt-out of the use of the{' '}
          DART cookie by visiting the Google Ad and Content Network privacy policy.
        </Span>
      </Policy>

      <Policy title="We have implemented the following:">
        We, along with third-party vendors such as Google use first-party cookies{' '}
        (such as the Google Analytics cookies) and third-party cookies{' '}
        (such as the DoubleClick cookie) or other third-party identifiers together to compile{' '}
        data regarding user interactions with ad impressions and other ad service functions{' '}
        as they relate to our website.
      </Policy>

      <Policy title="Opting out:">
        Users can set preferences for how Google advertises to you using the Google Ad Settings{' '}
        page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt{' '}
        Out page or by using the Google Analytics Opt Out Browser add on.
      </Policy>

      <Policy title="California Online Privacy Protection Act">
        CalOPPA is the first state law in the nation to require commercial websites and online{' '}
        services to post a privacy policy. The law's reach stretches well beyond California to{' '}
        require any person or company in the United States (and conceivably the world) that{' '}
        operates websites collecting Personally Identifiable Information from California{' '}
        consumers to post a conspicuous privacy policy on its website stating exactly the{' '}
        information being collected and those individuals or companies with whom it is being shared.

        <Span display="block" mt="sp2">
          See more at{' '}
          <Anchor
            color="primary"
            href="https://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
            target="_blank"
            rel="noreferrer noopener"
          >
            https://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
          </Anchor>
        </Span>
      </Policy>

      <Policy title="According to CalOPPA, we agree to the following:">
        Users can visit our site anonymously.

        <Span display="block" mt="sp2">
          Once this privacy policy is created, we will add a link to it on our home page or as{' '}
          a minimum, on the first significant page after entering our website.
        </Span>

        <Span display="block" mt="sp2">
          Our Privacy Policy link includes the word 'Privacy' and can easily be found on the{' '}
          page specified above.
        </Span>

        <Span display="block" mt="sp2">
          You will be notified of any Privacy Policy changes:
        </Span>

        <List my="sp3" pl="sp4">
          <li>On our Privacy Policy Page</li>
        </List>

        <Span display="block" mt="sp2">
          You will be notified of any Privacy Policy changes:
        </Span>

        <List my="sp3" pl="sp4">
          <li>By logging in to your account</li>
        </List>
      </Policy>

      <Policy title="How does our site handle Do Not Track signals?">
        We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a{' '}
        Do Not Track (DNT) browser mechanism is in place.
      </Policy>

      <Policy title="Does our site allow third-party behavioral tracking?">
        It's also important to note that we allow third-party behavioral tracking
      </Policy>

      <Policy title="COPPA (Children Online Privacy Protection Act)">
        When it comes to the collection of personal information from children under the age of{' '}
        13 years old, the Children's Online Privacy Protection Act (COPPA) puts parents in{' '}
        control. The Federal Trade Commission, United States' consumer protection agency, enforces{' '}
        the COPPA Rule, which spells out what operators of websites and online services must do to{' '}
        protect children's privacy and safety online.

        <Span display="block" mt="sp2">
          We do not specifically market to children under the age of 13 years old.
        </Span>

        <Span display="block" mt="sp2">
          Do we let third-parties, including ad networks or plug-ins collect PII from children{' '}
          under 13?
        </Span>
      </Policy>

      <Policy title="Fair Information Practices">
        The Fair Information Practices Principles form the backbone of privacy law in the United{' '}
        States and the concepts they include have played a significant role in the development of{' '}
        data protection laws around the globe. Understanding the Fair Information Practice{' '}
        Principles and how they should be implemented is critical to comply with the various{' '}
        privacy laws that protect personal information.
      </Policy>

      <Policy
        title={
          'In order to be in line with Fair Information Practices we will take the following' +
          ' responsive action, should a data breach occur:'
        }
      >
        We will notify you via email

        <List my="sp3" pl="sp4">
          <li>Within 7 business days</li>
        </List>

        <Span display="block" mt="sp2">
          We also agree to the Individual Redress Principle which requires that individuals have{' '}
          the right to legally pursue enforceable rights against data collectors and processors{' '}
          who fail to adhere to the law. This principle requires not only that individuals have{' '}
          enforceable rights against data users, but also that individuals have recourse to{' '}
          courts or government agencies to investigate and/or prosecute non-compliance by{' '}
          data processors.
        </Span>
      </Policy>

      <Policy title="CAN SPAM Act">
        The CAN-SPAM Act is a law that sets the rules for commercial email, establishes{' '}
        requirements for commercial messages, gives recipients the right to have emails stopped{' '}
        from being sent to them, and spells out tough penalties for violations.
      </Policy>

      <Policy title="We collect your email address in order to:">
        <List my="sp3" pl="sp4">
          <li>Send information, respond to inquiries, and/or other requests or questions</li>
          <li>Process orders and to send information and updates pertaining to orders</li>
          <li>Send you additional information related to your product and/or service</li>
          <li>
            Market to our mailing list or continue to send emails to our clients after the{' '}
            original transaction has occurred
          </li>
        </List>
      </Policy>

      <Policy title="To be in accordance with CANSPAM, we agree to the following:">
        <List my="sp3" pl="sp4">
          <li>Not use false or misleading subjects or email addresses.</li>
          <li>Identify the message as an advertisement in some reasonable way.</li>
          <li>Include the physical address of our business or site headquarters.</li>
          <li>Monitor third-party email marketing services for compliance, if one is used.</li>
          <li>Honor opt-out/unsubscribe requests quickly.</li>
          <li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
        </List>
      </Policy>

      <Policy
        title={
          'If at any time you would like to unsubscribe from receiving future emails,' +
          ' you can email us at'
        }
      >
        Follow the instructions at the bottom of each email and we will promptly remove you from{' '}
        ALL correspondence.
      </Policy>

      <Policy title="Contacting Us">
        If there are any questions regarding this privacy policy, you may contact us using the{' '}
        information below.

        <Span display="block" my="sp2" fontStyle="italic">
          Bonifacio Global City Taguig, NCR 1634 Philippines
        </Span>

        <Anchor
          color="primary"
          href="mailto:support@digipay.ph"
        >
          support@digipay.ph
        </Anchor>
      </Policy>
    </Section>
  )
}

export default PolicySection
